import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";

const DateSelector = (props: { dateChange: Function; date?: string }) => {
  const dateFormat = "DD/MM/YYYY";
  const defaultDateVal = dayjs(new Date());

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    date && props.dateChange(date, dateString);
  };

  return (
    <DatePicker
      allowClear={false}
      onChange={onChange}
      value={dayjs(props.date, dateFormat)}
      defaultValue={dayjs(defaultDateVal, dateFormat)}
      maxDate={defaultDateVal}
      format={dateFormat}
    />
  );
};

export default DateSelector;
