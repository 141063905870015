import styled from "styled-components";

export const TextToggleWrapper = styled.div`
  &.cursor-pointer {
    cursor: pointer;
  }
  width: 47rem !important;
  .truncate {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
