import { Col, Button, Empty, TableProps } from "antd";
import EmptyData from "../../../components/EmptyData/EmptyData";
import { Wrapper } from "../../../utils/TableStyle";
import { ContentWrapper } from "../CustomerDetailsStyled";
import { USER_LIST_TYPE } from "../CustomerDetailsData";
import { DetailsContentTitle } from "./UsersStyled";
import TablePagination from "../../../components/TablePagination/TablePagination";
import {
  reloadTable,
  setAssignPragmaticUserModalOpenSt,
  updateUserTableParam,
} from "../../../store/features/CustomerDetails.slice";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { GET } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import AssignUserModal from "./AssignUserModal/AssignUserModal";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { isInactiveCustomer, isSuperAdminUser } from "../../../utils/utils";
import { setOpenAddUserModal } from "../../../store/features/User.slice";
import AddUserModal, {
  USER_MODAL_TYPES,
} from "../../../components/AddUserModal/AddUserModal";
import UserTable from "./UserTable/UserTable";

const Users = () => {
  const dispatch = useAppDispatch();
  const { getItem } = useLocalStorage();
  const {
    pragmaticUsers,
    customers,
    customerDetails,
    userTableParam,
    reload_table,
    reload_customer_table,
    total_data,
  } = useAppSelector((state) => state.CustomerDetailsReducer);
  const [addUserBtnSt, setAddUserBtnSt] = useState(false);
  const isSuperAdmin = isSuperAdminUser();
  const customer = JSON.parse(`${getItem("currentCustomer")}`);
  const isInactiveUser = isInactiveCustomer();

  const getPragmaUsers = () => {
    dispatch(
      GET("customerDetails/assigned_users", _createUrl("pragmatic-users"))()
    );
  };

  const getCustomerUsers = () => {
    dispatch(
      GET(
        "customerDetails/assigned_customer_users",
        _createUrl("customer-users")
      )()
    );
  };

  useEffect(() => {
    if (reload_table) getPragmaUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_table]);

  useEffect(() => {
    if (reload_customer_table) getCustomerUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_customer_table]);

  useEffect(() => {
    setAddUserBtnSt(customers?.data?.length > 4);
  }, [customers?.data?.length]);

  useEffect(() => {
    if (customerDetails?.id) {
      dispatch(
        GET(
          "customerDetails/all_users",
          `${ApiUrl.customerList}${customerDetails?.id}/unassigned-users/`
        )()
      );
    }
  }, [customerDetails?.id, dispatch]);

  useEffect(() => {
    isSuperAdmin && dispatch(GET("users/roles", ApiUrl.roles)());
    getPragmaUsers();
    getCustomerUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //function on changing page
  const changePage = (pageNumber: number) => {
    dispatch(updateUserTableParam({ ...userTableParam, page: pageNumber }));
    dispatch(reloadTable(true));
  };

  const performTableAction = (type: string) => {
    switch (type) {
      case USER_LIST_TYPE.PRAGMATIC_USERS:
        dispatch(setAssignPragmaticUserModalOpenSt(true));
        break;
      case USER_LIST_TYPE.CUSTOMERS:
        dispatch(setOpenAddUserModal(true));
        break;
    }
  };

  const _createUrl = (type: string) => {
    let url = `${
      ApiUrl.customerList +
      (customerDetails.id ? customerDetails.id : customer.id)
    }/${type}/?page_size=5&page=${
      type === "pragmatic-users" ? userTableParam.page : "1"
    }`;
    if (userTableParam.ordering !== "")
      url = `${url}${userTableParam.ordering}`;
    return url;
  };

  //function on changing table props -> sort
  const changeTableProps: TableProps<any>["onChange"] = (
    _,
    __,
    sorter: any,
    extra
  ) => {
    switch (extra.action) {
      case "sort":
        let ordering = "";
        if (sorter.order === "descend" || sorter.order === "ascend") {
          ordering = `&ordering=${
            sorter.order === "descend"
              ? "-status"
              : sorter.order === "ascend"
              ? "status"
              : ""
          }`;
        }
        dispatch(
          updateUserTableParam({
            ...userTableParam,
            ordering,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {[pragmaticUsers, customers]?.length ? (
        <>
          {[pragmaticUsers, customers]?.map((item: any) => {
            return (
              <div key={item.title}>
                <DetailsContentTitle>
                  <div>
                    <div className="main-title">{item.title}</div>
                    <div className="subtitle">{item.subtitle}</div>
                  </div>
                  {isSuperAdmin && (
                    <Button
                      onClick={() => performTableAction(item.type)}
                      className="btn-outline"
                      disabled={
                        (item?.type === USER_LIST_TYPE.CUSTOMERS &&
                          addUserBtnSt) ||
                        isInactiveUser
                      }
                    >
                      {item.buttonLabel}
                    </Button>
                  )}
                </DetailsContentTitle>
                {item.data?.length > 0 ? (
                  <Wrapper>
                    <Col xs={24}>
                      <UserTable
                        item={item}
                        changeTableProps={changeTableProps}
                      />
                    </Col>
                    <div className="pagination d-flex">
                      <span className="item-count">
                        Showing&nbsp;
                        {item?.data?.length}
                        &nbsp; item(s) out of&nbsp;
                        {item?.type === USER_LIST_TYPE.PRAGMATIC_USERS
                          ? total_data
                          : item?.data?.length}{" "}
                        results found.
                      </span>
                      {item?.type === USER_LIST_TYPE.PRAGMATIC_USERS && (
                        <TablePagination
                          total={total_data}
                          onChange={changePage}
                          current={userTableParam?.page}
                          pageSize={5}
                        />
                      )}
                    </div>
                  </Wrapper>
                ) : (
                  <ContentWrapper className="empty-table">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </ContentWrapper>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <ContentWrapper>
          <EmptyData type="user" hideMessage={true} />
        </ContentWrapper>
      )}
      <AssignUserModal />
      <AddUserModal userType={USER_MODAL_TYPES.customer} />
    </>
  );
};

export default Users;
