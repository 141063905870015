import { useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useAppDispatch } from "./reduxHooks";
import { setAuthStatus } from "../store/features/Auth.slice";
import { checkUserInactivity } from "../utils/utils";

export const useAuth = () => {
  const { getItem } = useLocalStorage();
  const dispatch = useAppDispatch();
  const lastActive = getItem("last-active");
  const isAuth = getItem("isAuthenticated");

  useEffect(() => {
    let sessionExpired = false;
    if (lastActive) {
      sessionExpired = checkUserInactivity(lastActive);
    }
    if (isAuth && !sessionExpired) {
      dispatch(setAuthStatus(true));
    } else {
      dispatch(setAuthStatus(false));
      localStorage.removeItem("last-active");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, lastActive]);
};
