import { useNavigate } from "react-router-dom";
import UserInfoSection from "../../../components/UserInfoSection/UserInfoSection";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { DELETE } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { triggerToastMessage } from "../../../utils/utils";
import { updateToggleLoader } from "../../../store/features/Common.slice";
import { STATUS } from "../../../utils/constants/common";

const UserInfo = () => {
  const { id, name, email, status } = useAppSelector(
    (state) => state.UserDetailReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteUser = () => {
    dispatch(updateToggleLoader(true));
    dispatch(DELETE("user/delete", `${ApiUrl.userList}/${id}/`)()).then(
      (res: any) => {
        dispatch(updateToggleLoader(false));
        if (res?.payload?.status === STATUS.SUCCESS) {
          navigate("/home/users");
          triggerToastMessage(
            "User Deleted",
            undefined,
            `You deleted '${name}'`
          );
        }
      }
    );
  };

  return (
    <>
      <UserInfoSection
        data={{
          id: id,
          name: name,
          email: email,
          status: status,
        }}
        onDelete={deleteUser}
      />
    </>
  );
};

export default UserInfo;
