import { Col, Empty, Table } from "antd";
import EmptyData from "../../../components/EmptyData/EmptyData";
import { ContentWrapper } from "../../CustomerDetails/CustomerDetailsStyled";
import { Wrapper } from "../../../utils/TableStyle";
import TablePagination from "../../../components/TablePagination/TablePagination";
import { assignedCustomerColumns } from "../UserDetailsData";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { GET } from "../../../service/api";
import { useParams } from "react-router-dom";
import {
  reloadTable,
  updatePage,
} from "../../../store/features/UserDetail.slice";
import { LoaderLineComponent } from "../../../components/Loader/Loader";

const AssignedCustomers = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { assigned_customers, table_loader, total_data, page, reload_table } =
    useAppSelector((state) => state.UserDetailReducer);

  const getAssignedCustomers = () => {
    dispatch(
      GET(
        "users/assigned_customers",
        `${ApiUrl.userList}${id}/customers/?page=${page}`
      )()
    );
  };

  useEffect(() => {
    getAssignedCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reload_table) getAssignedCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_table]);

  const changePage = (page: number) => {
    dispatch(updatePage(page));
    dispatch(reloadTable(true));
  };

  return (
    <>
      {assigned_customers?.length ? (
        <Wrapper>
          <Col xs={24}>
            <Table
              size="middle"
              scroll={{ x: true }}
              className="custom-table"
              columns={assignedCustomerColumns}
              dataSource={assigned_customers}
              pagination={false}
              rowKey={"id"}
              loading={{
                indicator: <LoaderLineComponent />,
                spinning: table_loader,
              }}
            />
          </Col>
          <div className="pagination d-flex">
            {total_data && (
              <span className="item-count">
                Showing {assigned_customers?.length} item(s) out of&nbsp;
                {total_data} results found.
              </span>
            )}

            <TablePagination
              total={total_data}
              current={page}
              onChange={changePage}
            />
          </div>
        </Wrapper>
      ) : !table_loader ? (
        <ContentWrapper>
          <EmptyData type="customer" hideMessage={true} />
        </ContentWrapper>
      ) : (
        !table_loader && (
          <ContentWrapper>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </ContentWrapper>
        )
      )}
    </>
  );
};

export default AssignedCustomers;
