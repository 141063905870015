import { Button, Form, Input, Tooltip } from "antd";
import {
  REGISTRATION_STEPS,
  SET_PASSWORD_CARD_TITLE,
  STATUS,
  VALIDATION_ERRORS,
} from "../../../utils/constants/common";
import { passwordValidator } from "../../../utils/validators";
import {
  LoginWrapper,
  PasswordVisibilityToggleWrapper,
} from "../../Login/LoginStyled";
import { PasswordInstructions } from "../SignupStyled";
import { useState } from "react";
import {
  PasswordHiddenIcon,
  PasswordVisibleIcon,
} from "../../../components/Icons/Icons";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { POST } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { triggerToastMessage } from "../../../utils/utils";
import { RESPONSE_MESSAGES } from "../../../utils/constants/messages";

export const PasswordInfo = () => {
  return (
    <PasswordInstructions>
      <span>The password must contain at least:</span>
      <ul>
        <li>eight characters</li>
        <li>one uppercase character </li>
        <li>one lowercase character </li>
        <li>one number </li>
        <li>one special character</li>
      </ul>
    </PasswordInstructions>
  );
};

const CreatePassword = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createPasswordForm] = Form.useForm();
  const [btnStatus, setBtnStatus] = useState(true);
  const { setItem } = useLocalStorage();
  const { verified_user } = useAppSelector((state) => state.AuthReducer);
  const { userEmail, id, token } = verified_user;

  const triggerValidationOnBlur = (field: string) => {
    createPasswordForm.validateFields([field]);
  };

  const handleFormChange = () => {
    const hasErrors =
      !createPasswordForm.isFieldsTouched(["password"], true) ||
      createPasswordForm.getFieldsError().some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const onSubmit = () => {
    switch (title) {
      case SET_PASSWORD_CARD_TITLE.createPassword:
        setPassword();
        break;
      case SET_PASSWORD_CARD_TITLE.resetPassword:
        resetPassword();
        break;
      default:
        break;
    }
  };

  const setPassword = () => {
    if (id && token) {
      dispatch(
        POST("signup/createPassword", ApiUrl.createPassword, {
          id: id,
          token: token,
          new_password: createPasswordForm.getFieldValue("password"),
        })()
      )
        .then((res: any) => {
          if (res?.payload?.status === STATUS.SUCCESS) {
            const {
              payload: { data },
            } = res;
            setItem("token", data.token);
            setItem("user", JSON.stringify(data?.user));
            navigate(`/signup?step=${REGISTRATION_STEPS.MFA}`);
          }
        })
        .catch(() => {});
    }
  };

  const resetPassword = () => {
    dispatch(
      POST("resetPassword", ApiUrl.resetPassword, {
        id: id,
        token: token,
        new_password: createPasswordForm.getFieldValue("password"),
      })()
    )
      .then((res: any) => {
        if (res?.payload?.status === STATUS.SUCCESS) {
          triggerToastMessage(
            RESPONSE_MESSAGES.RESET_PASSWORD.message,
            STATUS.SUCCESS,
            ""
          );
          navigate(`/`);
        }
      })
      .catch(() => {});
  };

  return (
    <LoginWrapper>
      <div className="login">
        <div>
          <h4>{title} </h4>
          <p className="info">
            Secure your account <b>{userEmail}</b> with a new strong password.
          </p>
        </div>
        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          size="large"
          form={createPasswordForm}
          layout="vertical"
          onFinish={onSubmit}
          name="createPasswordForm"
          requiredMark={false}
        >
          <Form.Item
            className="password-input"
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: VALIDATION_ERRORS.password,
                validateTrigger: "onBlur",
              },
              {
                pattern: passwordValidator,
                message: VALIDATION_ERRORS.validPassword,
                validateTrigger: "onBlur",
              },
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              placeholder="Enter password"
              onBlur={() => triggerValidationOnBlur("password")}
              onChange={() => triggerValidationOnBlur("password")}
              iconRender={(visible) =>
                visible ? (
                  <Tooltip placement="top" title="Hide Password">
                    <PasswordVisibilityToggleWrapper
                      onClick={() => (visible = !visible)}
                    >
                      <PasswordHiddenIcon />
                    </PasswordVisibilityToggleWrapper>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title="Show Password">
                    <PasswordVisibilityToggleWrapper
                      onClick={() => (visible = !visible)}
                    >
                      <PasswordVisibleIcon />
                    </PasswordVisibilityToggleWrapper>
                  </Tooltip>
                )
              }
            />
          </Form.Item>
          <PasswordInfo />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="large-btn"
              block
              disabled={btnStatus}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </LoginWrapper>
  );
};

export default CreatePassword;
