import { Wrapper } from "../PageNotFound/PageNotFoundStyled";
// import expiredLinkIcon from "../../assets/images/expired-link.png"; // awaiting client confirmation
import { ExpiredIcon } from "../Icons/Icons";

const LinkExpiry = () => {
  return (
    <Wrapper>
      <div className="not-found-container d-flex place-center">
        <div>
          <ExpiredIcon />
          {/* <img src={expiredLinkIcon} /> */}
          {/* awaiting client confirmation */}
        </div>
        <div className="sub-div d-flex">
          <p className="header">Oops! This link has expired</p>
          <div className="text-div d-flex">
            <span className="info">Contact admin for assistance</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default LinkExpiry;
