import {
  AssignedCustomersTabIcon,
  UserInfoTabIcon,
} from "../../components/Icons/UserIcons";
import StatusTag from "../../components/StatusTag/StatusTag";
import { capitalizeFirstLetter } from "../../utils/utils";
import AssignedCustomers from "./AssignedCustomers/AssignedCustomers";
import UserInfo from "./UserInfo/UserInfo";

export const userDetailsPath = "/home/users/detail/";

export const userDetailTabs: any = [
  {
    key: "customers-assigned",
    label: "Customers Assigned",
    children: <AssignedCustomers />,
    icon: <AssignedCustomersTabIcon />,
  },
  {
    key: "user-info",
    label: "User Info",
    children: <UserInfo />,
    icon: <UserInfoTabIcon />,
  },
];

export const assignedCustomerColumns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
    render: (_: any, { name }: any) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    width: "25%",
    render: (_: any, { role }: any) => (
      <>
        <StatusTag content={role} />
      </>
    ),
  },
  {
    title: "Assigned Date",
    dataIndex: "added_date",
    width: "25%%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "25%",
    render: (_: any, { status }: any) => (
      <>
        <StatusTag content={status} />
      </>
    ),
  },
];

export const userStatusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  // { value: "Invited", label: "Invited" }, // Admin/User is not permitted to update to these status
  // { value: "Expired", label: "Expired" },
];
