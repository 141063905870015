export interface IAuth {
  isAuthenticated: boolean;
  status: string;
  user: IUser;
  verified_user: IVerifiedUser;
  store_status: StoreStatus;
}

export interface IVerifiedUser {
  id: string;
  token: string;
  userEmail: string;
}

export enum StoreStatus {
  Idle = "Idle",
  Loading = "Loading",
  Failed = "Failed",
  Succeeded = "Succeeded",
  Unverified = "Unverified",
}

export interface IUser {
  email: string;
  id: string;
  name: string;
  status: string;
  is_pragmatic_user: boolean;
  is_super_admin: boolean;
  is_node_exists: boolean;
  customer: {
    id: string;
    name: string;
    assigned_platforms: number;
    assigned_products: number;
  };
}
