import React from "react";
import { ICustomerSpecificFile } from "../../../../interface/CustomerDetails";
import { TreeNode } from "../../../../components/TreeView/TreeNode/TreeNode";
import { TreeWrapper } from "../../../../components/TreeView/TreeViewStyled";
import { useAppSelector } from "../../../../hooks/reduxHooks";

const CustomerFolderTree = () => {
  const { customer_specific_nodes } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  return (
    <TreeWrapper>
      {customer_specific_nodes.map((folder: ICustomerSpecificFile) => (
        <TreeNode
          type="customerSpecific"
          key={folder?.id}
          data={folder}
          id={folder?.id}
          name={folder?.name}
          isExpanded={folder?.expanded || false}
        />
      ))}
    </TreeWrapper>
  );
};

export default CustomerFolderTree;
