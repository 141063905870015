import { Dropdown, MenuProps } from "antd";
import { VerticalDots } from "../Icons/Icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setSelectedRecord } from "../../store/features/CustomerDetails.slice";

const ActionMenu = ({
  items,
  handleAction,
  data = {},
}: {
  items: MenuProps["items"];
  handleAction: Function;
  data?: any;
}) => {
  const dispatch = useAppDispatch();

  const handleMenuClick: MenuProps["onClick"] = (item: MenuInfo) => {
    handleAction(item);
    item.domEvent.stopPropagation();
    item.domEvent.preventDefault();
  };

  const selectRow = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedRecord(data));
  };

  return (
    <>
      <Dropdown
        className="more"
        trigger={["click"]}
        menu={{ items, onClick: (e) => handleMenuClick(e) }}
      >
        <span className="space" onClick={selectRow}>
          <VerticalDots />
        </span>
      </Dropdown>
    </>
  );
};

export default ActionMenu;
