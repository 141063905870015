import Documents from "../../../../components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { updateCustomerDocPage } from "../../../../store/features/CustomerDetails.slice";

const CustomerSpecificFiles = () => {
  const { customer_specific_files, doc_current_page, total_docs } =
    useAppSelector((state) => state.CustomerDetailsReducer);
  const dispatch = useAppDispatch();
  //function on changing page
  const changePage = (pageNumber: number) => {
    dispatch(updateCustomerDocPage(pageNumber));
  };

  return (
    <Documents
      isCustomerSpecific={true}
      docTitle="Uploads"
      data={customer_specific_files}
      tableParams={{ page: doc_current_page, total_data: total_docs }}
      onDocPageChange={changePage}
    />
  );
};

export default CustomerSpecificFiles;
