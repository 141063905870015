export const FolderIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0507 0.0888672H3.26663C2.15773 0.0888672 1.60328 0.0888672 1.17973 0.304674C0.807171 0.494504 0.504269 0.797405 0.31444 1.16997C0.0986328 1.59351 0.0986328 2.14796 0.0986328 3.25687V4.04887V13.1569C0.0986328 14.8202 0.0986328 15.6519 0.422343 16.2872C0.707087 16.8461 1.16144 17.3004 1.72028 17.5852C2.3556 17.9089 3.18728 17.9089 4.85063 17.9089H15.1466C16.81 17.9089 17.6417 17.9089 18.277 17.5852C18.8358 17.3004 19.2902 16.8461 19.5749 16.2872C19.8986 15.6519 19.8986 14.8202 19.8986 13.1569V8.80087C19.8986 7.13751 19.8986 6.30583 19.5749 5.67052C19.2902 5.11167 18.8358 4.65732 18.277 4.37258C17.6417 4.04887 16.81 4.04887 15.1466 4.04887H10.9886L9.88425 1.84009C9.5664 1.2044 9.40747 0.88654 9.17037 0.654319C8.9607 0.44896 8.70799 0.292781 8.43055 0.197084C8.1168 0.0888672 7.76144 0.0888672 7.0507 0.0888672Z"
      fill="#142D50"
      fillOpacity="0.75"
    />
  </svg>
);

export const FileIcon = () => (
  <svg
    width="41"
    height="48"
    viewBox="0 0 41 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.79 11.07C12.79 11.484 12.454 11.82 12.04 11.82H3.97754V45H37.0225V3H12.79V11.07Z"
      fill="#142D50"
    />
    <path d="M5.03711 10.32H11.2899V4.06201L5.03711 10.32Z" fill="#142D50" />
  </svg>
);

export const CloseBtn = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.00006L4 12.0001M4 4.00006L12 12.0001"
      stroke="#142D50"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GreenCheck = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66667 1H2.33333C1.59695 1 1 1.59695 1 2.33333V9.66667C1 10.403 1.59695 11 2.33333 11H9.66667C10.403 11 11 10.403 11 9.66667V2.33333C11 1.59695 10.403 1 9.66667 1Z"
      fill="#008675"
      stroke="white"
      strokeWidth="0.666667"
    />
    <path
      d="M3.6665 6.34809L5.08943 7.66665L8.33317 4.33331"
      stroke="white"
      strokeWidth="0.933333"
      strokeLinecap="square"
    />
  </svg>
);
