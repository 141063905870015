import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../service/api";
import { ITicketReducer } from "../../interface/CustomerDetails";

const initialState: ITicketReducer = {
  ticket_list: [],
  reload_table: false,
  ticketTableParams: { page: 1, ordering: "" },
  tickets_loader: false,
  total_pages: 0,
  total_data: 0,
};

const TicketsSlice = createSlice({
  name: "tickets_slice",
  initialState: initialState,
  reducers: {
    updateTicketsTableParam: (
      state,
      action: PayloadAction<{ page: number; ordering: string }>
    ) => {
      state.ticketTableParams = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    setTicketsLoader: (state, action: PayloadAction<boolean>) => {
      state.tickets_loader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET("tickets/list", "").fulfilled, (state, action) => {
      const { results, total_count, total_pages } = action.payload.data;
      state.ticket_list = results;
      state.total_pages = total_pages;
      state.total_data = total_count;
      state.tickets_loader = false;
    });
    builder.addCase(GET("tickets/list", "").rejected, (state, action) => {
      state.ticket_list = [];
      state.total_data = 0;
      state.total_pages = 0;
      state.tickets_loader = false;
    });
    builder.addCase(GET("tickets/list", "").pending, (state, action) => {
      state.tickets_loader = true;
      state.reload_table = false;
    });
  },
});

export default TicketsSlice.reducer;
export const { updateTicketsTableParam, reloadTable, setTicketsLoader } =
  TicketsSlice.actions;
