import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import "./service/interceptor";
import { PortalLoader } from "./components/Loader/Loader";
import { useAppSelector } from "./hooks/reduxHooks";
import { useAuth } from "./hooks/useAuth";
import { ToastMessage } from "./components/ToastMessage/ToastMessage";

const App = () => {
  useAuth();
  const { loader } = useAppSelector((state) => state.CommonReducer);
  return (
    <>
      <RouterProvider router={router} />
      {loader && <PortalLoader />}
      <ToastMessage />
    </>
  );
};

export default App;
