import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISignup } from "../../interface/Signup";
import { REGISTRATION_STEPS } from "../../utils/constants/common";
import { GET, POST } from "../../service/api";

const initialState: ISignup = {
  step: REGISTRATION_STEPS.CREATE_PASSWORD,
};
const SignupSlice = createSlice({
  name: "signup_reducer",
  initialState,
  reducers: {
    updateSignupStep: (state, action: PayloadAction<string>) => {
      state.step = action.payload;
    },
    resetSignupReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(POST("signup/createPassword", "").fulfilled, () => {});
    builder.addCase(POST("signup/createPassword", "").rejected, () => {});
    builder.addCase(POST("signup/generateQRCode", "").fulfilled, () => {});
    builder.addCase(POST("signup/generateQRCode", "").rejected, () => {});
    builder.addCase(GET("signup/downloadCodes", "").fulfilled, () => {});
    builder.addCase(GET("signup/downloadCodes", "").rejected, () => {});
    builder.addCase(GET("signup/getRecoveryTokens", "").fulfilled, () => {});
    builder.addCase(GET("signup/getRecoveryTokens", "").rejected, () => {});
  },
});
export default SignupSlice.reducer;
export const { updateSignupStep, resetSignupReducer } = SignupSlice.actions;
