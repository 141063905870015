import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { INodes } from "../../interface/ProductDetails";
import { IFolderTree } from "../../interface/Tree";
import { DELETE, GET } from "../../service/api";

export const nodeInitialSt = {
  id: "",
  name: "",
  sub_nodes: [],
  is_root_node: false,
  expanded: false,
  loading: false,
  type: "",
  path: [],
  hasFiles: false,
};

const initialState: IFolderTree = {
  nodes: nodeInitialSt,
  active_node: nodeInitialSt,
  customer_active_node: nodeInitialSt,
  fetchNode: false,
  breadcrumbs: [],
  breadcrumbNodes: [],
  document_loader: false,
  openDeleteModal: false,
  openRenameFolderModal: false,
  pragma_docs: [],
  total_pages: 0,
  total_data: 0,
  reload_table: false,
  page: 1,
  confirmFolderDelete: false,
  confirmRenameFolder: false,
  renamedFolder: { node: "", new_name: "" },
};
const FolderTreeSlice = createSlice({
  name: "product_details_reducer",
  initialState,
  reducers: {
    initialiseNodes: (state, action: PayloadAction<INodes>) => {
      state.nodes = action.payload;
      state.active_node = action.payload;
      state.reload_table = false;
      state.breadcrumbs = [{ title: action?.payload?.name }];
    },
    setNodes: (state, action: PayloadAction<INodes>) => {
      state.nodes = action.payload;
    },
    setActiveNode: (state, action: PayloadAction<INodes>) => {
      state.active_node = action.payload;
    },
    setCustomerActiveNode: (state, action: PayloadAction<INodes>) => {
      state.customer_active_node = action.payload;
    },
    setReloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    setBreadCrumb: (state, action: PayloadAction<{ title: string }[]>) => {
      state.breadcrumbs = [...action.payload];
    },
    setBreadCrumbNodes: (
      state,
      action: PayloadAction<{ id: string; name: string }[]>
    ) => {
      state.breadcrumbNodes = [...action.payload];
    },
    fetchNodeDetails: (state, action: PayloadAction<boolean>) => {
      state.fetchNode = action.payload;
    },

    updateDocPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDocumentsLoader: (state, action: PayloadAction<boolean>) => {
      state.document_loader = action.payload;
    },
    setOpenDeleteFolderModalSt: (state, action: PayloadAction<boolean>) => {
      state.openDeleteModal = action.payload;
    },
    setOpenRenameFolderModal: (state, action: PayloadAction<boolean>) => {
      state.openRenameFolderModal = action.payload;
    },
    setConfirmFolderDelete: (state, action: PayloadAction<boolean>) => {
      state.confirmFolderDelete = action.payload;
    },
    setConfirmRenameFolder: (state, action: PayloadAction<boolean>) => {
      state.confirmRenameFolder = action.payload;
    },
    renameNode: (
      state,
      action: PayloadAction<{ node: string; new_name: string }>
    ) => {
      state.renamedFolder = action.payload;
    },

    resetCommonReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("nodes/files", "").fulfilled, (state, action) => {
      const { results, total_count, total_pages } = action.payload.data;
      state.pragma_docs = results;
      state.total_data = total_count;
      state.total_pages = total_pages;
      state.document_loader = false;
      state.reload_table = false;
    });
    builder.addCase(GET("nodes/files", "").rejected, (state, action) => {
      state.pragma_docs = [];
      state.total_data = 0;
      state.total_pages = 0;
      state.reload_table = false;
    });

    builder.addMatcher(
      isAnyOf(
        GET("customerSpecific/files", "").pending,
        GET("nodes/files", "").pending,
        DELETE("nodes/delete", "").pending
      ),
      (state) => {
        state.document_loader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("customerSpecific/files", "").fulfilled,
        GET("customerSpecific/files", "").rejected,
        GET("nodes/files", "").rejected,
        DELETE("nodes/delete", "").rejected,
        DELETE("nodes/delete", "").fulfilled
      ),
      (state) => {
        state.document_loader = false;
      }
    );
  },
});
export default FolderTreeSlice.reducer;
export const {
  initialiseNodes,
  setActiveNode,
  setCustomerActiveNode,
  setNodes,
  fetchNodeDetails,
  updateDocPage,
  setBreadCrumb,
  setBreadCrumbNodes,
  setDocumentsLoader,
  setReloadTable,
  setOpenDeleteFolderModalSt,
  setOpenRenameFolderModal,
  setConfirmFolderDelete,
  setConfirmRenameFolder,
  renameNode,
  resetCommonReducer,
} = FolderTreeSlice.actions;
