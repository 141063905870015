import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ICustomerActivityLog,
  ICustomerInfoReducer,
} from "../../interface/CustomerDetails";
import { GET } from "../../service/api";
import dayjs from "dayjs";

const initialState: ICustomerInfoReducer = {
  activityLogParams: {
    selectedDate: dayjs(new Date())?.format("DD/MM/YYYY")?.toString(),
    page: 1,
    page_size: 50,
  },
  activity_log: [{ id: "", date: "", activity: "", user: "", role: "" }],
  activity_list_loader: true,
  reload_list: true,
  total_data: 0,
  total_pages: 0,
};

const CustomerInfoSlice = createSlice({
  name: "customer_info_reducer",
  initialState: initialState,
  reducers: {
    setActivityLog: (state, action: PayloadAction<ICustomerActivityLog[]>) => {
      state.activity_log = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.activityLogParams.selectedDate = action.payload;
      state.activityLogParams.page = 1;
      state.activityLogParams.page_size = 50;
      state.reload_list = true;
    },
    setActivityPage: (state, action: PayloadAction<number>) => {
      state.activityLogParams.page = action.payload;
      state.reload_list = true;
    },
    setActivityPageSize: (state, action: PayloadAction<number>) => {
      state.activityLogParams.page_size = action.payload;
      state.reload_list = true;
    },
    reloadList: (state, action: PayloadAction<boolean>) => {
      state.reload_list = action.payload;
    },
    resetCommonReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("activity-logs/list", "").fulfilled,
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        state.activity_log = results;
        state.total_pages = total_pages;
        state.total_data = total_count;
        state.activity_list_loader = false;
        state.reload_list = false;
      }
    );
  },
});

export default CustomerInfoSlice.reducer;
export const {
  setActivityLog,
  setSelectedDate,
  setActivityPage,
  reloadList,
  resetCommonReducer,
  setActivityPageSize,
} = CustomerInfoSlice.actions;
