import { LoginWrapper } from "./LoginStyled";
import LoginForm from "./LoginForm/LoginForm";
import { useState } from "react";
import MultifactorAuthentication from "./MultifactorAuthentication/MultifactorAuthentication";
import RecoveryCode from "./RecoveryCode/RecoveryCode";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [showRecoveryCode, setShowRecoverCode] = useState(false);
  const title = isLogin
    ? "Login"
    : !showRecoveryCode
    ? "Authenticate your account"
    : "Recovery Code";
  const subTitle = isLogin
    ? "Please enter your details"
    : !showRecoveryCode
    ? "Enter the 6-digit login code generated by your authentication app."
    : "Enter one of the recovery codes that you had received when you first set up two-factor authentication.";

  const backToLogin = () => {
    setShowRecoverCode(false);
    setIsLogin(false);
  };

  return (
    <LoginWrapper>
      <div className="login">
        <div>
          <h4>{title}</h4>
          <p className="info">{subTitle}</p>
        </div>
        {isLogin ? (
          <LoginForm setLoginSt={() => setIsLogin(false)} />
        ) : !showRecoveryCode ? (
          <MultifactorAuthentication
            setRecoveryCodeUI={() => setShowRecoverCode(true)}
          />
        ) : (
          <RecoveryCode backToLogin={backToLogin} />
        )}
      </div>
    </LoginWrapper>
  );
};

export default Login;
