export type TMenuItem = {
  identifier: string;
  path: string;
  label: string;
  position: string;
  icon: string;
};

export const SUPER_ADMIN_MENU: Array<TMenuItem> = [
  {
    identifier: "customers",
    path: "/home/customers",

    label: "Customers",
    position: "top",
    icon: "CustomerMenuIcon",
  },
  {
    identifier: "products",
    path: "/home/products",
    label: "Products and Platforms",
    position: "top",
    icon: "ProductMenuIcon",
  },
  {
    identifier: "users",
    path: "/home/users",
    label: "Users",
    position: "top",
    icon: "UserMenuIcon",
  },
  // {
  //   identifier: "settings",
  //   path: "/home/settings",
  //   label: "Settings",
  //   position: "top",
  //   icon: React.createElement(SettingsIcon),
  // },
  {
    identifier: "blocked_ips",
    path: "/home/blocked_ips",
    label: "Blocked IPs",
    position: "bottom",
    icon: "BlockedIPsIcon",
  },
  {
    identifier: "logout",
    path: "",
    label: "Logout",
    position: "bottom",
    icon: "LogoutIcon",
  },
];

export const VIEWER_MENU: Array<TMenuItem> = [
  {
    identifier: "customers",
    path: "/home/customers",
    label: "Customers",
    position: "top",
    icon: "CustomerMenuIcon",
  },
  {
    identifier: "products",
    path: "/home/products",
    label: "Products and Platforms",
    position: "top",
    icon: "ProductMenuIcon",
  },
  {
    identifier: "logout",
    path: "",
    label: "Logout",
    position: "bottom",
    icon: "LogoutIcon",
  },
];

export const COMMON_MENU: TMenuItem[] = [
  {
    identifier: "contact_us",
    path: "/home/contact_us",
    label: "Contact Us",
    position: "bottom",
    icon: "ContactUsIcon",
  },
  {
    identifier: "logout",
    path: "",
    label: "Logout",
    position: "bottom",
    icon: "LogoutIcon",
  },
];

export const PRODUCT_CUSTOMERS: TMenuItem[] = [
  {
    identifier: "products",
    path: "/home/products",
    label: "Products",
    position: "top",
    icon: "ProductIcon",
  },
  {
    identifier: "documentation",
    path: "/home/documentation",
    label: "Documentation",
    position: "top",
    icon: "DocumentationIcon",
  },
  ...COMMON_MENU,
];
export const PLATFORM_CUSTOMERS: TMenuItem[] = [
  {
    identifier: "platforms",
    path: "/home/platforms",
    label: "Platforms",
    position: "top",
    icon: "PlatformMenuIcon",
  },
  {
    identifier: "documentation",
    path: "/home/documentation",
    label: "Documentation",
    position: "top",
    icon: "DocumentationIcon",
  },
  {
    identifier: "designs",
    path: "/home/designs",
    label: "Designs",
    position: "top",
    icon: "DesignsIcon",
  },
  {
    identifier: "test_data",
    path: "/home/test_data",
    label: "Test Data",
    position: "top",
    icon: "TestDataIcon",
  },
  ...COMMON_MENU,
];

export const PROD_PLATFORM_CUSTOMERS: TMenuItem[] = [
  {
    identifier: "products",
    path: "/home/products",
    label: "Products",
    position: "top",
    icon: "ProductIcon",
  },
  ...PLATFORM_CUSTOMERS,
];

export const MENU_TEXTS: {
  [key: string]: { title: string; subText: string; path: string };
} = {
  customers: {
    title: "Customers",
    subText: "Review and manage customers",
    path: "customers",
  },
  products_and_platforms: {
    title: "Products and Platforms",
    subText: "Review and manage products/platforms",
    path: "products",
  },
  products: {
    title: "Products",
    subText: "",
    path: "products",
  },
  no_products: {
    title: "Products and Platforms",
    subText: "",
    path: "products",
  },
  platforms: {
    title: "Platforms",
    subText: "",
    path: "platforms",
  },
  documentation: {
    title: "Documentation",
    subText: "",
    path: "documentation",
  },
  designs: {
    title: "Designs",
    subText: "",
    path: "designs",
  },
  test_data: {
    title: "Test Data",
    subText: "",
    path: "test_data",
  },
  users: {
    title: "Users",
    subText: "Review and manage users",
    path: "users",
  },
  blocked_ips: {
    title: "Blocked IPs",
    subText: "Review and manage blocked IP addresses",
    path: "blocked_ips",
  },
  activity_log: {
    title: "Activity Log",
    subText: "Activities under this customer",
    path: "activity_log",
  },
  my_files: {
    title: "My Files",
    subText: "Review and manage files",
    path: "my_files",
  },
};
