import styled from "styled-components";

export const ContentWrapper = styled.div`
  .title {
    font-size: 3.6rem;
    color: var(--PRIMARY);
    font-weight: 400;
    margin: 0;
  }
  .sub-title {
    font-size: 1.8rem;
    font-weight: 300;
    color: var(--SECONDARY);
    margin-top: 1.2rem;
  }
`;
