import styled from "styled-components";

export const OtpGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 36rem;
  column-gap: 1rem;

  .otp-input {
    width: 5.8rem;
    height: 6.8rem;
    border: 1px solid rgba(20, 45, 80, 0.25);
    border-radius: 8px;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 5.8rem;
    padding: 0.78rem;
    color: var(--BLACK);
    &:focus {
      border: 1px solid var(--PRIMARY);
    }
  }
  .seperator {
    margin: auto;
    color: #667085;
    font-size: 4rem;
  }
`;
