import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUploadsReducer } from "../../interface/Uploads";
import { GET } from "../../service/api";
import { INodes } from "../../interface/ProductDetails";
import { FILE_UPLOAD_CATEGORIES } from "../../utils/constants/common";

const initialState: IUploadsReducer = {
  uploads: [],
  nodes: {
    documentations: "",
    designs: "",
  },
  page_loader: true,
  table_loader: true,
  reload_table: false,
  page: 1,
  total_data: 0,
};

const UploadsSlice = createSlice({
  name: "customer_uploads",
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET("customer/uploads", "").fulfilled, (state, action) => {
      const { results, total_count } = action.payload.data;
      state.uploads = results.length ? results : [];
      state.total_data = total_count;
      state.table_loader = false;
    });
    builder.addCase(GET("customer/uploads", "").pending, (state, action) => {
      state.table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(GET("customer/nodes", "").fulfilled, (state, action) => {
      const { nodes } = action.payload.data;
      nodes.forEach((element: INodes) => {
        if (element.name === FILE_UPLOAD_CATEGORIES.documentations)
          state.nodes.documentations = element.id;
        if (element.name === FILE_UPLOAD_CATEGORIES.designs) state.nodes.designs = element.id;
      });
    });
  },
});

export default UploadsSlice.reducer;

export const { updatePage, reloadTable } = UploadsSlice.actions;
