import { useState } from "react";
import Otp from "../../../components/Otp/Otp";
import { Button } from "antd";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { POST } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { STATUS } from "../../../utils/constants/common";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { updateToggleLoader } from "../../../store/features/Common.slice";

const MultifactorAuthentication = ({
  setRecoveryCodeUI,
}: {
  setRecoveryCodeUI: Function;
}) => {
  const [code, setCode] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setItem } = useLocalStorage();

  const onChange = (value: string, submit = false) => {
    setCode(value);
    if (submit) submitOtp();
  };

  const submitOtp = () => {
    dispatch(updateToggleLoader(true));
    dispatch(POST("auth/otpVerification", ApiUrl.otp, { code })())
      .then((data: any) => {
        dispatch(updateToggleLoader(false));
        if (data?.payload?.status === STATUS.SUCCESS) {
          setItem("isAuthenticated", "true");
          navigate("/home");
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Otp value={code} valueLength={6} onChange={onChange} autoFocus={true} />
      <div>
        <Button
          disabled={code.length !== 6}
          className="large-btn"
          block
          type="primary"
          onClick={submitOtp}
        >
          Submit
        </Button>
        <p
          className="code-text recovery"
          onClick={() => setRecoveryCodeUI(true)}
        >
          Enter recovery code, instead
        </p>
      </div>
    </>
  );
};

export default MultifactorAuthentication;
