import { Pagination, PaginationProps } from "antd";

const TablePagination = (props: PaginationProps) => {
  const {
    onChange = undefined,
    hideOnSinglePage = true,
    defaultCurrent = 1,
    current = 1,
    total = 0,
    pageSize = 10,
  } = props;
  return (
    <Pagination
      hideOnSinglePage={hideOnSinglePage}
      defaultCurrent={defaultCurrent}
      total={total}
      current={current}
      onChange={onChange}
      showSizeChanger={false}
      defaultPageSize={pageSize}
    />
  );
};

export default TablePagination;
