import styled from "styled-components";
import leftBgImg from "../../assets/images/no-file-left-bg.png";
import rightBgImg from "../../assets/images/no-file-right-bg.png";
import UploadButton from "../UploadButton/UploadButton";

const NoFileWrapper = styled.div`
  width: 60%;
  border: 1px solid var(--BORDER);
  border-radius: 1.2rem;
  box-shadow: 0rem 1rem 2.4rem -0.4rem #1018280d;
  padding: 2.4rem 2.4rem 0 2.4rem;
  .images {
    display: flex;
    flex: 1;
    position: relative;
    img {
      position: absolute;
    }
    img:first-child {
      bottom: 0;
      right: 47px;
    }
    img:last-child {
      right: -24px;
      bottom: 0;
    }
  }

  .no-file-content {
    display: flex;
    flex-direction: column;
    gap: 6.4rem;
  }
  .info {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
  }
`;
const NoFiles = ({
  addFolder,
  isCustomerSpecific = false,
}: {
  addFolder?: Function;
  isCustomerSpecific?: boolean;
}) => {
  return (
    <NoFileWrapper className="d-flex">
      <div className="no-file-content">
        <p className="info">Organize and manage folders and files</p>
        <UploadButton
          addFolder={addFolder}
          isCustomerSpecific={isCustomerSpecific}
        />
      </div>
      <div className="images">
        <img src={leftBgImg} alt="No file bg" />
        <img src={rightBgImg} alt="No file bg" />
      </div>
    </NoFileWrapper>
  );
};

export default NoFiles;
