import { Form, Select } from "antd";
import { useState } from "react";
import { CategoryOption, FileWarning } from "./SelectCategoryStyled";
import { FileUploadWarning } from "../../Icons/Icons";

export interface ICategoryOptions {
  label: string;
  value: string;
  showWarning: boolean;
  icon?: any;
  labelWithIcon?: any;
}

const transformedOptions = (options: Array<ICategoryOptions>) => {
  return options.map((option) => {
    return {
      ...option,
      labelWithIcon: (
        <span>
          <CategoryOption>
            {option.icon} {option.label}
          </CategoryOption>
        </span>
      ),
    };
  });
};

const SelectCategory = ({
  options,
  setCategory,
  setBtnStatus,
}: {
  options: Array<ICategoryOptions>;
  setCategory: Function;
  setBtnStatus: Function;
}) => {
  const [cateforyForm] = Form.useForm();
  const [selected, setSelected] = useState<ICategoryOptions | null>(null);

  const triggerValidationOnBlur = (field: string) => {
    cateforyForm.validateFields([field]);
  };

  return (
    <>
      <Form
        autoComplete="off"
        size="large"
        form={cateforyForm}
        layout="vertical"
        name="cateforyForm"
        requiredMark={false}
      >
        <Form.Item
          name={"category"}
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select category and upload file",
              validateTrigger: "onBlur",
            },
          ]}
        >
          <Select
            className="category-select"
            style={{ width: "20rem" }}
            placeholder="Select Category"
            onSelect={(_, option): any => {
              setSelected(option);
              setCategory(option);
              setBtnStatus(false);
            }}
            options={transformedOptions(options)}
            optionLabelProp="labelWithIcon"
            value={selected}
            onBlur={() => triggerValidationOnBlur("category")}
            optionRender={(option) => (
              <CategoryOption>
                {option.data.icon} {option.label}
              </CategoryOption>
            )}
          />
        </Form.Item>
      </Form>
      {selected?.showWarning && (
        <FileWarning>
          <FileUploadWarning />
          Make sure that no files containing sensitive or confidential
          information have been uploaded to the Design category.
        </FileWarning>
      )}
    </>
  );
};

export default SelectCategory;
