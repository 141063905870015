export const AssignedCustomersTabIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.96963 8.00898H7.05454L7.05522 2.49504L2.96963 5.0024V8.00898ZM0.500087 8.73735H8.19563V14.7716H8.94522L8.94454 8.00891H7.80419L7.80487 0.5L14.5851 4.66085V14.7716H15.875V15.5H0.125V14.7716H1.41486V9.46562H0.499461L0.500087 8.73735ZM3.24487 14.7716H3.99445V12.4828H5.61526V14.7716H6.36484V11.7544H3.2448L3.24487 14.7716Z"
      fill="#142D50"
    />
  </svg>
);

export const UserInfoTabIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.5 4.5V3.5H7.5V4.5H6.5ZM6.5 10.5V5.5H7.5V10.5H6.5Z"
      fill="#142D50"
    />
  </svg>
);
