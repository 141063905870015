import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { IAuth, IVerifiedUser, StoreStatus } from "../../interface/Auth";
import { POST } from "../../service/api";

const initialState: IAuth = {
  isAuthenticated: false,
  status: "NV",
  user: {
    id: "",
    name: "",
    email: "",
    is_pragmatic_user: false,
    status: "",
    is_super_admin: false,
    is_node_exists: false,
    customer: {
      id: "",
      name: "",
      assigned_platforms: 0,
      assigned_products: 0,
    },
  },
  verified_user: {
    userEmail: "",
    id: "",
    token: "",
  },
  store_status: StoreStatus.Loading,
};

const AuthSlice = createSlice({
  name: "auth_reducer",
  initialState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      state.store_status = StoreStatus.Succeeded;
    },
    setVerifiedUserData: (state, action: PayloadAction<IVerifiedUser>) => {
      state.verified_user = action.payload;
    },
    resetAuthReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(POST("auth/login", "").fulfilled, () => {});
    builder.addCase(POST("auth/login", "").rejected, () => {});
    builder.addCase(POST("auth/otpVerification", "").rejected, () => {});
    builder.addMatcher(
      isAnyOf(
        POST("auth/otpVerification", "").fulfilled,
        POST("auth/recoveryCodeLogin", "").fulfilled
      ),
      (state) => {
        state.isAuthenticated = true;
      }
    );
  },
});

export default AuthSlice.reducer;
export const { setAuthStatus, setVerifiedUserData, resetAuthReducer } =
  AuthSlice.actions;
