import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBlockedIPsReducer } from "../../interface/BlockedIP";
import { GET, POST } from "../../service/api";

const initialState: IBlockedIPsReducer = {
  page: 0,
  total_data: 0,
  blocked_ips_list: [],
  reload_table: true,
  table_loader: true,
};

const BlockedIpsSlice = createSlice({
  name: "blocked_ips_reducer",
  initialState,
  reducers: {
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET("blocked_ips/list", "").fulfilled, (state, action) => {
      const { results, total_count } = action.payload.data;
      state.blocked_ips_list = results.length ? results : [];
      state.total_data = total_count;
      state.table_loader = false;
    });
    builder.addCase(GET("blocked_ips/list", "").pending, (state, action) => {
      state.table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(GET("blocked_ips/list", "").rejected, (state, action) => {
      state.blocked_ips_list = [];
      state.total_data = 0;
      state.table_loader = false;
    });
    builder.addCase(POST("unblock_ip", "").fulfilled, (state, action) => {});
  },
});

export default BlockedIpsSlice.reducer;

export const { updatePage, reloadTable } = BlockedIpsSlice.actions;
