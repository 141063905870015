import React from "react";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import { MenuProps } from "antd";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import {
  setSelectedRecord,
  setUnassignUserActionModalSt,
} from "../../../store/features/CustomerDetails.slice";
import { isSuperAdminUser } from "../../../utils/utils";

const UserTableAction = ({
  data,
  items,
}: {
  data: any;
  items: MenuProps["items"];
}) => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = isSuperAdminUser();
  const handleMenuClick = (item: any) => {
    dispatch(setUnassignUserActionModalSt(true));
    dispatch(setSelectedRecord(data));
  };

  return (
    <>
      {isSuperAdmin && (
        <div className="actions">
          <ActionMenu
            items={items}
            handleAction={(item: any) => handleMenuClick(item)}
          />
        </div>
      )}
    </>
  );
};

export default UserTableAction;
