import styled from "styled-components";

const TagWrapper = styled.div<{ type: string }>`
  .tag {
    align-items: center;
    width: fit-content;
    height: 2.9rem;
    background: ${(props) => `var(--${props?.type?.toUpperCase()})`};
    padding: 4px 6px;
    color: ${(props) => `var(--TEXT-${props?.type?.toUpperCase()})`};
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 6px;
  }
`;

const StatusTag = ({ content }: { content: string }) => {
  return (
    <TagWrapper type={content}>
      <div className="tag d-flex">{content}</div>
    </TagWrapper>
  );
};

export default StatusTag;
