import { Input, Button, Form, Tooltip } from "antd";
import {
  STATUS,
  USER_STATUS,
  VALIDATION_ERRORS,
} from "../../../utils/constants/common";
import { emailValidator, spaceRegex } from "../../../utils/validators";
import { ChangeEvent, useState } from "react";
import { PasswordVisibilityToggleWrapper, ActionText } from "../LoginStyled";
import {
  PasswordHiddenIcon,
  PasswordVisibleIcon,
} from "../../../components/Icons/Icons";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { POST } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { updateToggleLoader } from "../../../store/features/Common.slice";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ setLoginSt }: { setLoginSt: Function }) => {
  const [loginForm] = Form.useForm();
  const [btnStatus, setBtnStatus] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setItem } = useLocalStorage();

  const triggerValidationOnBlur = (field: string) => {
    loginForm.validateFields([field]);
  };

  const handleFormChange = () => {
    const hasErrors =
      !loginForm.isFieldsTouched(["email", "password"], true) ||
      loginForm.getFieldsError().some(({ errors }) => errors?.length);
    setBtnStatus(hasErrors);
  };

  const signin = () => {
    dispatch(updateToggleLoader(true));
    const payload = {
      ...loginForm.getFieldsValue(),
      email: loginForm.getFieldValue("email").toLowerCase(),
    };
    dispatch(
      POST("auth/login", ApiUrl.login, {
        ...payload,
      })()
    )
      .then((res: any) => {
        dispatch(updateToggleLoader(false));

        if (res?.payload?.status === STATUS.SUCCESS) {
          const {
            payload: { data },
          } = res;
          setItem("token", data?.token);
          setItem("user", JSON.stringify(data?.user));
          // Checking whether logged in user has setup 2fa, if 2fa not configured navigate to registration and display signup page
          if (data?.status === USER_STATUS.AV) {
            setLoginSt();
          } else {
            navigate("/signup?step=mfa");
          }
        }
      })
      .catch(() => {});
  };

  const inputPassword = (e: ChangeEvent<HTMLInputElement>) => {
    if (spaceRegex.test(e.target.value)) {
      e.target.value = "";
    }
  };

  const goToResetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Form
      onFieldsChange={handleFormChange}
      autoComplete="off"
      size="large"
      form={loginForm}
      layout="vertical"
      onFinish={signin}
      name="loginForm"
      requiredMark={false}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: VALIDATION_ERRORS.email,
            validateTrigger: "onBlur",
          },
          {
            pattern: emailValidator,
            message: VALIDATION_ERRORS.validEmail,
            validateTrigger: "onBlur",
          },
        ]}
      >
        <Input
          autoFocus
          autoComplete="new-user"
          placeholder="Enter your email address"
          onBlur={() => triggerValidationOnBlur("email")}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: VALIDATION_ERRORS.password,
            validateTrigger: "onBlur",
          },
        ]}
      >
        <Input.Password
          onInput={inputPassword}
          autoComplete="new-password"
          placeholder="Enter password"
          onBlur={() => triggerValidationOnBlur("password")}
          iconRender={(visible) =>
            visible ? (
              <Tooltip placement="top" title="Hide Password">
                <PasswordVisibilityToggleWrapper
                  onClick={() => (visible = !visible)}
                >
                  <PasswordHiddenIcon />
                </PasswordVisibilityToggleWrapper>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Show Password">
                <PasswordVisibilityToggleWrapper
                  onClick={() => (visible = !visible)}
                >
                  <PasswordVisibleIcon />
                </PasswordVisibilityToggleWrapper>
              </Tooltip>
            )
          }
        />
      </Form.Item>
      <ActionText onClick={goToResetPassword} $align="right">
        Forgot your password?
      </ActionText>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="large-btn"
          block
          disabled={btnStatus}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
