import StatusTag from "../StatusTag/StatusTag";
import styled from "styled-components";
import { IUserDetails } from "../../interface/UserDetail";
import { capitalizeFirstLetter } from "../../utils/utils";

const UserDataWrapper = styled.div`
  display: flex;
  gap: 8.4rem;
  .data-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .label {
      font-size: 1.2rem;
      font-weight: 400;
      color: var(--USER-DETAIL-LABEL);
    }
    .item {
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--PRIMARY);
      height: 2.9rem;
    }
  }
`;

const DataItem = ({ label, item }: { label: string; item: any }) => {
  return (
    <span className="data-item">
      <span className="label">{label}</span>
      <span className="item">{item}</span>
    </span>
  );
};

const UserData = ({
  type = "User",
  data,
}: {
  type?: string;
  data: IUserDetails;
}) => {
  return (
    <UserDataWrapper>
      <DataItem
        label={type === "User" ? "Name" : `${type} Name`}
        item={capitalizeFirstLetter(data.name)}
      />
      {type === "User" && <DataItem label="Email" item={data.email} />}
      <DataItem label="Status" item={<StatusTag content={data.status} />} />
    </UserDataWrapper>
  );
};

export default UserData;
