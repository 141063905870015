import { Row } from "antd";
import styled from "styled-components";

export const LoginWrapper = styled(Row)`
  font-family: Roboto Flex;
  color: var(--PRIMARY) !important;
  .large-btn {
    height: 5.6rem;
    margin-top: 1.6rem;
    font-weight: 600;
    font-size: 1.7rem;
  }
  h4 {
    font-size: 2.8rem;
    margin: 0;
  }
  .login {
    width: 51rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    background-color: var(--WHITE);
    .ant-form-item {
      margin: 1.6rem 0 !important;
    }
    .ant-form-item:first-child {
      margin-top: 0px !important;
    }
    .info {
      color: var(--SECONDARY);
      font-weight: 300;
      font-size: 1.8rem;
      margin: 1.2rem 0 0;
    }
    @media (min-width: 993px) {
      padding: 5.6rem 4.2rem;
      border: 1px solid rgba(20, 45, 80, 0.25);
      border-radius: 1.2rem;
      box-shadow: 0px 20px 24px -4px #10182814;
    }
  }
  .ant-input-affix-wrapper {
    height: 4.8rem !important;
    align-items: center;
  }
  .code-text {
    color: var(--PRIMARY);
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    &.recovery {
      text-decoration: underline;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .link-back {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
`;

export const PasswordVisibilityToggleWrapper = styled.span`
  display: inherit;
`;

export const ActionText = styled.div<{ $align: string }>`
  color: var(--PRIMARY);
  font-size: 1.4rem;
  margin-top: 2rem;
  text-align: ${(props) => props?.$align};
  font-weight: 600;
  cursor: pointer;
  .text {
    margin-left: 10px;
    font-size: 1.6rem;
  }
`;
