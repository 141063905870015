import { notification } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setToastMessage } from "../../store/features/Common.slice";
import { STATUS } from "../../utils/constants/common";
import { ToastCloseIcon, ToastSuccessIcon } from "../Icons/Icons";

// Null will use the default Icon provided by Ant Design.
const TOAST_ICONS = {
  success: <ToastSuccessIcon />,
  info: null,
  warning: null,
  error: null,
};

export const ToastMessage = () => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const { showToast, toastMessage, toastType, toastDescription } =
    useAppSelector((state) => state.CommonReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showToast) {
      api.open({
        message: toastMessage,
        description: toastDescription,
        className: "toast-message",
        style: {
          minWidth: "34rem",
          width: "auto",
          fontSize: "1.2rem",
          color: "white",
        },
        duration: 2,
        placement: "bottomRight",
        type: toastType,
        icon: toastType ? TOAST_ICONS[toastType] : null,
        closeIcon: <ToastCloseIcon />,
      });
      dispatch(
        setToastMessage({
          showToast: false,
          toastMessage: "",
          toastType: STATUS.SUCCESS,
          toastDescription: "",
        })
      );
    }
  }, [showToast, api, dispatch, toastMessage, toastType, toastDescription]);
  return <>{contextHolder}</>;
};
