import { Form, Input, Modal } from "antd";
import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { POST } from "../../service/api";
import { updateToggleLoader } from "../../store/features/Common.slice";
import {
  updateUserTableParam,
  setOpenAddUserModal,
  reloadTable,
} from "../../store/features/User.slice";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { STATUS, VALIDATION_ERRORS } from "../../utils/constants/common";
import { RESPONSE_MESSAGES } from "../../utils/constants/messages";
import { triggerToastMessage } from "../../utils/utils";
import { spaceRegex, emailValidator } from "../../utils/validators";
import { reloadCustomerTable } from "../../store/features/CustomerDetails.slice";

export enum USER_MODAL_TYPES {
  user = "user",
  customer = "customer",
}

const AddUserModal = (props: { userType: string }) => {
  const IS_PRAGMATIC_USER = true;
  const dispatch = useAppDispatch();

  const [btnStatus, setBtnStatus] = useState(true);
  const [addUserForm] = Form.useForm();
  const { open_user_modal } = useAppSelector((state) => state.UserReducer);
  const { customerDetails } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );

  const submit = () => {
    switch (props.userType) {
      case USER_MODAL_TYPES.customer:
        addCustomerUser();
        break;
      case USER_MODAL_TYPES.user:
        addUser();
        break;
      default:
        break;
    }
  };

  const addUser = () => {
    let payload = {
      ...addUserForm.getFieldsValue(),
      is_pragmatic_user: IS_PRAGMATIC_USER,
    };
    payload = {
      ...payload,
      email: payload.email?.toLowerCase(),
    };
    dispatch(updateToggleLoader(true));
    dispatch(POST("users/add", ApiUrl.userList, payload)()).then((res: any) => {
      dispatch(updateToggleLoader(false));
      if (res?.payload?.status === STATUS.SUCCESS) {
        handleResponse(USER_MODAL_TYPES.user);
        dispatch(reloadTable(true));
        dispatch(updateUserTableParam({ ordering: "", page: 1 }));
      }
    });
  };

  const addCustomerUser = () => {
    dispatch(updateToggleLoader(true));
    const payload: any = {
      user: {
        name: addUserForm.getFieldValue("name"),
        email: addUserForm.getFieldValue("email")?.toLowerCase(),
      },
      customer: customerDetails.id,
    };
    dispatch(
      POST("assigned_users/add_customer_user", ApiUrl.addCustomerUser, {
        ...payload,
      })()
    ).then((res: any) => {
      dispatch(updateToggleLoader(false));
      if (res?.payload?.status === STATUS.SUCCESS) {
        handleResponse(USER_MODAL_TYPES.customer);
        dispatch(reloadCustomerTable(true));
      }
    });
  };

  const handleResponse = (type: any) => {
    const message =
      type === USER_MODAL_TYPES.user
        ? RESPONSE_MESSAGES.ADD_USER.message
        : RESPONSE_MESSAGES.ADD_CUSTOMER_USER.message;
    const description =
      type === USER_MODAL_TYPES.user
        ? RESPONSE_MESSAGES.ADD_USER.description
        : RESPONSE_MESSAGES.ADD_CUSTOMER_USER.description;

    triggerToastMessage(`${message}`, STATUS.SUCCESS, `${description}`);
    reset();
  };

  const reset = () => {
    dispatch(setOpenAddUserModal(false));
    addUserForm.resetFields();
    setBtnStatus(true);
  };

  const handleFormChange = () => {
    const hasErrors =
      !addUserForm.isFieldsTouched(["name"], true) ||
      addUserForm.getFieldsError().some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const triggerValidationOnBlur = (field: string) => {
    const val = addUserForm.getFieldValue(field);
    addUserForm.setFieldsValue({ [field]: val?.trim() });
    addUserForm.validateFields([field]);
  };

  const isNameEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    if (spaceRegex.test(e.target.value)) {
      e.target.value = "";
    }
  };

  const blockSpaceCharacter = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value?.trim();
  };

  return (
    <>
      <Modal
        destroyOnClose
        className="modal-title"
        title="Add User"
        open={open_user_modal}
        okText="Add"
        okButtonProps={{ size: "large", disabled: btnStatus }}
        cancelButtonProps={{ size: "large", type: "text" }}
        onOk={submit}
        onCancel={reset}
        closable={false}
      >
        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          size="large"
          form={addUserForm}
          layout="vertical"
          name="addUserForm"
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: VALIDATION_ERRORS.name,
                validateTrigger: "onBlur",
              },
              {
                min: 3,
                message: "User name should be atleast 3 characters",
                validateTrigger: "onBlur",
              },
            ]}
          >
            <Input
              onInput={isNameEmpty}
              autoComplete="new-name"
              placeholder="Enter User Name"
              onBlur={() => triggerValidationOnBlur("name")}
              minLength={3}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: VALIDATION_ERRORS.email,
                validateTrigger: "onBlur",
              },
              {
                pattern: emailValidator,
                message: VALIDATION_ERRORS.validEmail,
                validateTrigger: "onBlur",
              },
            ]}
          >
            <Input
              onInput={blockSpaceCharacter}
              autoComplete="new-email"
              placeholder="Enter User Email"
              onBlur={() => triggerValidationOnBlur("email")}
              minLength={3}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddUserModal;
