import styled from "styled-components";

export const ContentWrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: calc(100vh - 25rem);
  &.empty-table {
    min-height: 20rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .add-btn {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--WHITE);
    height: 4.6rem;
  }
`;

export const CustomerSpecificWrapper = styled.section`
  height: 100%;
  display: flex;
`;
