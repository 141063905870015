import { ColumnsType } from "antd/es/table";
import { capitalizeFirstLetter, downloadDoc } from "../../utils/utils";
import { IFile } from "../../interface/ProductDetails";
import { DownloadArrow } from "../Icons/Icons";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { GET } from "../../service/api";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { STATUS } from "../../utils/constants/common";
import { updateToggleLoader } from "../../store/features/Common.slice";
import { ActionButtonWrapper } from "../../pages/Uploads/UploadsColumndata";
import { Tooltip } from "antd";

export const DownloadFile = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch();

  const download = (id: string) => {
    dispatch(updateToggleLoader(true));
    dispatch(
      GET("nodes/downloadFile", `${ApiUrl.downloadDocument}${id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        const { detail } = res?.payload?.data;
        downloadDoc(detail);
      }
      dispatch(updateToggleLoader(false));
    });
  };

  return (
    <span className="cursor-pointer" onClick={() => download(id)}>
      <DownloadArrow />
    </span>
  );
};

export const documentColumns: ColumnsType<IFile> = [
  {
    title: "File Name",
    dataIndex: "name",
    width: "60%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "File Size",
    dataIndex: "formatted_size",
    width: "15%",
  },
  {
    title: "Uploaded Date",
    dataIndex: "uploaded_date",
    width: "15%",
  },
  {
    title: "Actions",
    dataIndex: "",
    width: "10%",
    align: "center",
    render: ({ id }) => (
      <Tooltip placement="top" title="Download">
        <ActionButtonWrapper>
          <DownloadFile id={id} />
        </ActionButtonWrapper>
      </Tooltip>
    ),
  },
];
