import { ColumnsType } from "antd/es/table";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { IProductTable } from "../../interface/Product";
import {
  CustomerProductColumns,
  ProductColumns,
} from "./ProductTableColumnData";

export type ProductTableType = {
  endPoint: string;
  showSearch: boolean;
  showPagination: boolean;
  columns: ColumnsType<IProductTable>;
  action: string;
};

export type ProductTableTypes = {
  PRODUCTS_AND_PLATFORMS: ProductTableType;
  CUSTOMER_PRODUCTS: ProductTableType;
};

export const PRODUCT_TABLE_TYPES: ProductTableTypes = {
  PRODUCTS_AND_PLATFORMS: {
    endPoint: ApiUrl.productList,
    showSearch: true,
    showPagination: true,
    columns: ProductColumns,
    action: "products/list",
  },
  CUSTOMER_PRODUCTS: {
    endPoint: ApiUrl.customerProductList,
    showSearch: false,
    showPagination: true,
    columns: CustomerProductColumns,
    action: "customer-products/list",
  },
};
