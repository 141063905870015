import styled from "styled-components";

export const Spacer = styled.div`
  padding: 1rem;
`;

export const ActivityWrapper = styled.div<{ role: string }>`
  display: flex;
  flex-direction: column;
  .title {
    font-size: 1.4rem;
    color: #142d50;
  }
  .subtext {
    display: flex;
    align-items: center;
    .name {
      font-size: 1rem;
      font-weight: 500;
      color: #142d50;
    }
    .dot {
      height: 0.3rem;
      width: 0.25rem;
      background-color: #142d5080;
      border-radius: 50%;
      margin: 0 0.5rem;
    }
    .role {
      font-size: 1rem;
      font-weight: 700;
      color: ${(props) =>
        `var(--TEXT-${props?.role
          ?.toUpperCase()
          .replace(" ", "")}) !important`};
    }
  }
`;

export const UserActivity = ({
  message,
  user,
  role,
}: {
  message: string;
  user: string;
  role: string;
}) => {
  return (
    <>
      <ActivityWrapper role={role}>
        <div className="title">{message}</div>
        <div className="subtext">
          <div className="name">{user}</div>
          <div className="dot"></div>
          <div className="role">{role}</div>
        </div>
      </ActivityWrapper>
    </>
  );
};

export const UserActivityData = [
  {
    user: "Aman Gupta",
    role: "Individual",
    message: "Viewed Agreement file",
    event_time: `${new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`,
  },
  {
    user: "Malu Nair",
    role: "Super Admin",
    message: "Created folder in “Helvelyn 2.1.0”",
    event_time: "02:18 PM",
  },
  {
    user: "Aman Gupta",
    role: "Individual",
    message: "Viewed Design file",
    event_time: "11:59 AM",
  },
  {
    user: "Malu Nair",
    role: "Super Admin",
    message: "Added individual user “Sachin Gopakumar”",
    event_time: "11:21 AM",
  },
  {
    user: "Malu Nair",
    role: "Super Admin",
    message: "Added individual users",
    event_time: "10:01 AM",
  },
  {
    user: "Malu Nair",
    role: "Super Admin",
    message: "Uploaded customer specific files",
    event_time: "9:21 AM",
  },
];
