import ProductList from "../../../components/ProductList/ProductList";
import { useParams } from "react-router-dom";

const ProductsList = () => {
  const { id } = useParams();
  const endpoint = `customers/${id}/nodes/`;

  return <ProductList endPoint={endpoint} type="CUSTOMER_PRODUCTS" />;
};

export default ProductsList;
