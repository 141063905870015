import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-info-header {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 2.4rem;
      font-weight: 500;
      color: var(--PRIMARY);
    }
    .sub {
      font-size: 1.8rem;
      font-weight: 300;
      color: var(--SECONDARY);
    }
  }
  .del-btn {
    border-width: 0.2rem;
    border-radius: 0.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    height: 4.4rem;
  }
`;
export const UserDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid var(--PAGE-BORDER);
  border-radius: 0.8rem;
  padding: 2.2rem 2.8rem;
  .edit-btn {
    border: 2px solid var(--PRIMARY);
    border-radius: 0.6rem;
    padding: 1rem 1.5rem;
    color: var(--PRIMARY);
    font-size: 1.6rem;
    weight: 600;
  }
`;

export const FormLineItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
`;
