import styled from "styled-components";

export const LoaderBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
`;

export const LoaderLine = styled.div`
  position: relative;
  width: 200px;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--WHITE);
    border-bottom-color: var(--PRIMARY);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PortalLoaderBox = styled.div`
  position: absolute;
  z-index: 9999;
  background-color: var(--TRANSPARENT);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  transition: 0.3s;
`;
export const TreeLoadWrapper = styled.div`
  .loader {
    margin: auto;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid var(--WHITE);
    border-bottom-color: var(--PRIMARY);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const DocumentLoaderBox = styled.div<{ show: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.show ? "-1" : "99")};
  transition: 0.3s;
  opacity: ${(props) => (props.show ? "0" : "1")};
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  background-color: var(--WHITE);
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  .loader-message-holder {
    color: var(--PRIMARY);
    font-family: GothamMedium;
    font-weight: 350;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    top: 0;
    transform: translate(120%, 45%);
  }
`;
