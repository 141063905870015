import styled from "styled-components";

export const OptionStyle = styled.div`
  padding: 1.3rem;
  font-size: 1.6rem;
  color: #142d50bf;

  .primary-text {
    font-weight: 500;
    color: #142d50;
    font-size: 1.6rem;
  }
  .secondary-text {
    font-weight: 400;
    color: #142d5080;
    font-size: 1.2rem;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
