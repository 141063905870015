import { Col, Row } from "antd";
import emptyData from "../../assets/images/illustration/empty-data.png";
import styled from "styled-components";
import { isIndividualUser, isSuperAdminUser } from "../../utils/utils";

const EmptyWrapper = styled(Row)`
  display: flex;
  margin: auto;
  .align-center {
    text-align: center;
  }
  .message {
    color: var(--NEUTRAL-4);
    font-size: 1.8rem;
  }
`;

const EmptyData = ({
  type,
  hideMessage,
  customMessage,
  subTextType = "Add",
  image,
}: {
  type: string;
  hideMessage?: boolean;
  customMessage?: string;
  subTextType?: string;
  image?: string;
}) => {
  const isSuperAdmin = isSuperAdminUser();
  const msg = customMessage
    ? customMessage
    : isSuperAdmin
    ? `No ${type}s added.`
    : isIndividualUser()
    ? `No ${type}s shared with you.`
    : `No ${type}s assigned yet.`;
  return (
    <EmptyWrapper>
      <Col xs={24} className="align-center">
        <img src={image || emptyData} alt="No data" />
      </Col>
      <Col xs={24} className="align-center">
        <span className="message">{msg}</span>
        <br />
        {!hideMessage && (
          <span className="message">
            {isSuperAdmin ? (
              <>
                Give it a go by clicking the&nbsp;
                <strong>
                  '{subTextType} {type}'&nbsp;
                </strong>
                button.
              </>
            ) : (
              "Contact Admin for assistance."
            )}
          </span>
        )}
      </Col>
    </EmptyWrapper>
  );
};

export default EmptyData;
