import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { VERSION } from "../utils/constants/ApiUrl";

const GET = (reducer: string, endpoint: string, options: any = "") =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        options
      );
      return options ? response : response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });

const POST = (reducer: string, endpoint: string, body: any = {}) =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        body
      );
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });

const PUT = (reducer: string, endpoint: string, body: any = {}) =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        body
      );
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });

const POST_FORMDATA = (reducer: string, endpoint: string, body: any = {}) =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        body,
        config
      );
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });

const PATCH = (reducer: string, endpoint: string, body: any = {}) =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        body
      );
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });
const DELETE = (reducer: string, endpoint: string, body: any = {}) =>
  createAsyncThunk(reducer, async (_, thunkApi) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASE_URL + VERSION + endpoint,
        body
      );
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  });

export { GET, POST, POST_FORMDATA, PATCH, PUT, DELETE };
