import React from "react";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Wrapper } from "./PageNotFoundStyled";
import noAccessImg from "../../assets/images/illustration/access-denied.png";
import notFoundImg from "../../assets/images/illustration/page-not-found.png";

const PageNotFound = ({ noAccess = false }: { noAccess?: boolean }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="not-found-container d-flex place-center">
        <div>
          <img
            src={noAccess ? noAccessImg : notFoundImg}
            alt={noAccess ? "No Access" : "Page Not Found"}
          />
        </div>

        <div className="sub-div d-flex">
          <p className="header">
            {noAccess ? "Access Denied" : "Page Not Found"}
          </p>
          <div className="text-div d-flex">
            <span className="info">
              {!noAccess
                ? "The page you are looking for doesn't exist or has been moved."
                : "You don't have permission to view this page."}
            </span>

            <br />
            {!noAccess ? (
              <span className="info">
                Try going to&nbsp;
                <Link className="link" to={"/"}>
                  pragmatic_customer_portal.com
                </Link>
              </span>
            ) : (
              <div>
                <Button className="home-btn" onClick={() => navigate("/home")}>
                  Go to home
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PageNotFound;
