import { Fragment, useMemo } from "react";
import { DigitRegex } from "../../utils/validators";
import { OtpGroup } from "./OtpStyled";

export type Props = {
  valueLength: number;
  value: any;
  onChange: (value: string, submit?: boolean) => void;
  autoFocus: boolean;
};

export default function Otp({
  value,
  valueLength,
  onChange,
  autoFocus,
}: Props) {
  //const dispatch = useAppDispatch();
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (DigitRegex.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  //function to focus next input
  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;
    const focusedElem =
      nextElementSibling?.className === "seperator"
        ? (nextElementSibling?.nextSibling as HTMLInputElement)
        : nextElementSibling;
    if (focusedElem) {
      focusedElem.focus();
    }
  };

  //function to focus previous input
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    const focusedElem =
      previousElementSibling?.className === "seperator"
        ? (previousElementSibling?.previousSibling as HTMLInputElement)
        : previousElementSibling;
    if (focusedElem) {
      focusedElem.focus();
    }
  };

  //function on changing the inout OTP value
  const changeOtpInput = (e: any, idx: number) => {
    e.preventDefault();
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = DigitRegex.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);
      onChange(newValue.trim());

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      target.blur();
    }
  };

  //function on keydown
  const inputOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);
    //submit code on pressing enter key
    if (targetValue.length === 1 && e.key === "Enter") {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);
      onChange(newValue.trim(), true);
    }
    if (e.key !== "Backspace" || target.value !== "") {
      if (value.split("")[idx] === e.key) {
        changeOtpInput(e, idx);
      }
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <OtpGroup>
      {valueItems.map((digit, idx) => (
        <Fragment key={`${idx}`}>
          <input
            key={`${idx}`}
            type="text"
            maxLength={valueLength}
            pattern="\d{1}"
            className="otp-input"
            tabIndex={idx + 1}
            onChange={(e) => changeOtpInput(e, idx)}
            onKeyDown={(e) => inputOnKeyDown(e, idx)}
            onFocus={inputOnFocus}
            value={digit}
            autoFocus={autoFocus && idx === 0}
          />
          {idx === 2 && (
            <span key={"span"} className="seperator">
              -
            </span>
          )}
        </Fragment>
      ))}
    </OtpGroup>
  );
}
