import styled from "styled-components";

export const FileWarning = styled.p`
  background-color: #fff3f5;
  font-weight: 300;
  font-size: 1.6rem;
  color: #2b0606;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const CategoryOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem
`;
