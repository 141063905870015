import React from "react";
import {
  LoaderLine,
  LoaderBox,
  PortalLoaderBox,
  TreeLoadWrapper,
  DocumentLoaderBox,
} from "./LoaderStyled";
import ReactDOM from "react-dom";

export const LoaderLineComponent = () => {
  return (
    <LoaderLine>
      <div className="loader"></div>
    </LoaderLine>
  );
};

export const TreeLoader = () => {
  return (
    <TreeLoadWrapper>
      <div className="loader"></div>
    </TreeLoadWrapper>
  );
};

export const DocumentLoader = ({ show = false }) => {
  return (
    <DocumentLoaderBox show={show}>
      <LoaderLine>
        <div className="loader-line"></div>
      </LoaderLine>
    </DocumentLoaderBox>
  );
};

const Loader: React.FC = () => {
  return (
    <LoaderBox>
      <LoaderLineComponent />
    </LoaderBox>
  );
};

const portalLoaderDOM = document.getElementById("portal-loader") as HTMLElement;
export const PortalLoader: React.FC = () => {
  return ReactDOM.createPortal(
    <PortalLoaderBox>
      <LoaderLineComponent />
    </PortalLoaderBox>,
    portalLoaderDOM
  );
};

export default Loader;
