import { useAppSelector } from "../../hooks/reduxHooks";
import { ContentWrapper } from "./ContentHeaderStyled";

const ContentHeader = () => {
  const { header } = useAppSelector((state) => state.CommonReducer);
  return (
    <>
      {/* title and subtitle will be loaded dynamically in future development */}
      <ContentWrapper>
        <h2 className="title">{header?.title}</h2>
        <p className="sub-title">{header?.subText}</p>
      </ContentWrapper>
    </>
  );
};

export default ContentHeader;
