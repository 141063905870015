import axios from "axios";
import { ERROR_CODES, HTTP_METHODS, STATUS } from "../utils/constants/common";
import { triggerToastMessage } from "../utils/utils";
import { ApiUrl } from "../utils/constants/ApiUrl";

const EXCLUDED_URLS: string[] = [ApiUrl.login];

axios.interceptors.request.use(
  (config) => {
    if (config?.url) {
      if (EXCLUDED_URLS.some((url) => !config.url?.includes(url))) {
        const token = localStorage.getItem("token");
        config.headers["Authorization"] = `Token ${token}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.config.method !== HTTP_METHODS.GET) {
      const {
        data: { message },
      } = response;
      message && triggerToastMessage(message, STATUS.SUCCESS);
    }
    return response;
  },
  async function (err) {
    if (err?.response) {
      const {
        response: {
          status,
          data: { message, data },
        },
      } = err;
      const errKey = data && Object.keys(data);
      const errVal = data && Object.values(data);
      const errMsg = message
        ? message
        : errKey?.length > 0 && Array.isArray(errVal[0])
        ? errVal[0]
        : "Something went wrong";
      triggerToastMessage(errMsg, STATUS.ERROR);
      if (status && status === ERROR_CODES.UNAUTHORIZED) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }
      return Promise.reject(err);
    }
    triggerToastMessage("Something went wrong", STATUS.ERROR);
    return;
  }
);
