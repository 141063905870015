export const ProductsTabIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.46774 11.5167C1.46774 11.7831 1.66639 11.9992 1.91123 11.9992L13.9557 12C14.0737 12 14.1858 11.9498 14.2694 11.8588C14.3522 11.7687 14.3992 11.6459 14.3992 11.5183V3.80703L1.46774 3.80623V11.5167ZM0.5 0.5C0.5 0.223858 0.723858 0 1 0H6.9339V1.93265H7.3424V0H8.82192V1.93265H9.28104V0H15C15.2761 0 15.5 0.223858 15.5 0.5V2.60461C15.5 2.88075 15.2761 3.10461 15 3.10461H1C0.723858 3.10461 0.5 2.88075 0.5 2.60461V0.5Z"
      fill="#142D50"
    />
  </svg>
);
export const UsersTabIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.196 15.5001C13.196 11.2331 11.5769 7.78027 8.76996 7.78027H4.8613C2.25863 7.78027 0.804688 11.2331 0.804688 15.5001"
      fill="#142D50"
      fillOpacity="0.5"
    />
    <path
      d="M6.66958 7.03218C8.47834 7.03218 9.94462 5.5699 9.94462 3.76609C9.94462 1.96228 8.47834 0.5 6.66958 0.5C4.86082 0.5 3.39453 1.96228 3.39453 3.76609C3.39453 5.5699 4.86082 7.03218 6.66958 7.03218Z"
      fill="#142D50"
      fillOpacity="0.5"
    />
  </svg>
);
export const CustomerSpecificTabIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33333 15.5C1.09722 15.5 0.899306 15.4201 0.739583 15.2604C0.579861 15.1007 0.5 14.9028 0.5 14.6667V12.6458C0.5 12.4236 0.541667 12.2118 0.625 12.0104C0.708333 11.809 0.826389 11.6319 0.979167 11.4792L11.5 0.979167C11.6667 0.826389 11.8507 0.708333 12.0521 0.625C12.2535 0.541667 12.4653 0.5 12.6875 0.5C12.9097 0.5 13.125 0.541667 13.3333 0.625C13.5417 0.708333 13.7222 0.833333 13.875 1L15.0208 2.16667C15.1875 2.31944 15.309 2.5 15.3854 2.70833C15.4618 2.91667 15.5 3.125 15.5 3.33333C15.5 3.55556 15.4618 3.76736 15.3854 3.96875C15.309 4.17014 15.1875 4.35417 15.0208 4.52083L4.52083 15.0208C4.36806 15.1736 4.19097 15.2917 3.98958 15.375C3.78819 15.4583 3.57639 15.5 3.35417 15.5H1.33333ZM12.6667 4.5L13.8333 3.33333L12.6667 2.16667L11.5 3.33333L12.6667 4.5Z"
      fill="#8996A7"
    />
  </svg>
);
export const TicketsTabIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.431641 1.25C0.431641 0.697715 0.879356 0.25 1.43164 0.25H14.568C15.1203 0.25 15.568 0.697715 15.568 1.25V8.60714C15.568 11.4475 13.2655 13.75 10.4251 13.75H1.43164C0.879357 13.75 0.431641 13.3023 0.431641 12.75V1.25ZM4.57127 3.78898C4.57127 4.22169 4.22049 4.57246 3.78778 4.57246C3.35508 4.57246 3.0043 4.22169 3.0043 3.78898C3.0043 3.35627 3.35508 3.0055 3.78778 3.0055C4.22049 3.0055 4.57127 3.35627 4.57127 3.78898ZM13.2905 3.46416H5.5762V4.10701H13.2905V3.46416ZM4.57035 6.96322C4.57035 7.39593 4.21957 7.7467 3.78687 7.7467C3.35416 7.7467 3.00338 7.39593 3.00338 6.96322C3.00338 6.53051 3.35416 6.17974 3.78687 6.17974C4.21957 6.17974 4.57035 6.53051 4.57035 6.96322ZM11.3608 6.63817H5.57507V7.28102H11.3608V6.63817ZM4.57035 10.1373C4.57035 10.57 4.21957 10.9208 3.78687 10.9208C3.35416 10.9208 3.00338 10.57 3.00338 10.1373C3.00338 9.70462 3.35416 9.35384 3.78687 9.35384C4.21957 9.35384 4.57035 9.70462 4.57035 10.1373ZM5.57507 9.81256H8.78936V10.4554H5.57507V9.81256Z"
      fill="#142D50"
      fillOpacity="0.5"
    />
  </svg>
);
export const CustomerInfoTabIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.5 4.5V3.5H7.5V4.5H6.5ZM6.5 10.5V5.5H7.5V10.5H6.5Z"
      fill="#142D50"
      fillOpacity="0.5"
    />
  </svg>
);
