import { ColumnsType } from "antd/es/table";
import { IFile } from "../../interface/ProductDetails";
import { capitalizeFirstLetter } from "../../utils/utils";
import { DownloadFile } from "../../components/Documents/DocumentsTableColumnData";
import styled from "styled-components";

export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const uploadColumns: ColumnsType<IFile> = [
  {
    title: "File Name",
    dataIndex: "name",
    width: "35%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Category",
    dataIndex: "category",
    width: "15%",
  },
  {
    title: "Uploaded By",
    dataIndex: "uploaded_by",
    width: "15%",
  },
  {
    title: "File Size",
    dataIndex: "formatted_size",
    width: "10%",
  },
  {
    title: "Uploaded Date",
    dataIndex: "uploaded_date",
    width: "15%",
  },
  {
    title: "Actions",
    dataIndex: "",
    width: "10%",
    align: "center",
    render: ({ id }) => (
      <ActionButtonWrapper>
        <DownloadFile id={id} />
      </ActionButtonWrapper>
    ),
  },
];
