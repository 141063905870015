import { Select } from "antd";
import { OptionStyle } from "./SelectOptionStyled";
import StatusTag from "../StatusTag/StatusTag";
import { useState } from "react";

const SelectOption = (props: {
  options: any;
  mode?: "multiple" | "tags" | undefined;
  style: any;
  placeholder: string;
  defaultValue?: { label: string; value: string };
  maxCount?: number;
  onChange?: any;
  disabled?: boolean;
}) => {
  const {
    options,
    mode,
    style,
    placeholder,
    defaultValue,
    maxCount,
    onChange,
    disabled,
  } = props;
  const [open, setOpen] = useState(false);

  const handleChange = (value: any) => {
    maxCount === value.length && value.length > 0 && setOpen(false);
    onChange(value);
  };

  return (
    <Select
      disabled={disabled}
      mode={mode ?? undefined}
      maxCount={maxCount}
      allowClear
      style={style}
      open={open}
      placeholder={placeholder}
      defaultValue={defaultValue ? defaultValue : []}
      onChange={handleChange}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      options={options}
      filterOption={(input, option) => {
        return (`${option?.label}${option?.subtitle}` ?? "")
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      optionRender={(option) => (
        <OptionStyle>
          <div className="container">
            <div>
              <div className="primary-text">{option.data.label}</div>
              <div className="secondary-text">{option.data.subtitle}</div>
            </div>
            <StatusTag content={option.data.type} />
          </div>
        </OptionStyle>
      )}
    />
  );
};

export default SelectOption;
