import { Input } from "antd";
import { CloseIcon, SearchIcon } from "../Icons/Icons";
import styled from "styled-components";

const SearchInp = styled.div`
  .ant-input-affix-wrapper {
    height: 4.8rem !important;
    align-items: center;
  }
`;

const Search = ({
  placeholder,
  searchData,
  searchVal,
}: {
  placeholder: string;
  searchData: Function;
  searchVal: string;
}) => {
  return (
    <SearchInp>
      <Input
        className="mb-2 search-inp"
        size="large"
        value={searchVal}
        onChange={(e) => searchData(e.target.value)}
        placeholder={placeholder}
        suffix={
          searchVal ? (
            <span className="cursor-pointer" onClick={() => searchData("")}>
              <CloseIcon />
            </span>
          ) : null
        }
        prefix={<SearchIcon />}
      />
    </SearchInp>
  );
};

export default Search;
