import { useEffect, useState } from "react";
import { Wrapper } from "../../../../utils/TableStyle";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  reloadTable,
  setAssignPragmaticUserModalOpenSt,
} from "../../../../store/features/CustomerDetails.slice";
import { Form } from "antd";
import { CustomerDetailsAddModal } from "./AssignUserModalStyled";
import SelectOption from "../../../../components/SelectOption/SelectOption";
import { updateToggleLoader } from "../../../../store/features/Common.slice";
import { GET, POST } from "../../../../service/api";
import { triggerToastMessage } from "../../../../utils/utils";
import { RESPONSE_MESSAGES } from "../../../../utils/constants/messages";
import {
  STATUS,
  USER_ROLES,
  VALIDATION_ERRORS,
} from "../../../../utils/constants/common";
import { ApiUrl } from "../../../../utils/constants/ApiUrl";
import { IRoles } from "../../../../interface/User";

interface ISelect {
  label: string;
  value: string;
  subtitle?: string;
}

const AssignUserModal = () => {
  const [assignUsersForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const { openPragmaticUserModal, customerDetails, total_pragma_users } =
    useAppSelector(
      (state: { CustomerDetailsReducer: any }) => state.CustomerDetailsReducer
    );
  const { roles } = useAppSelector((state) => state.UserReducer);
  const [btnStatus, setBtnStatus] = useState(true);
  const [pragmaticUsers, setPragmaticUsers] = useState<ISelect[]>([]);
  const [filteredRoles, setFilteredRoles] = useState<ISelect[]>([]);

  useEffect(() => {
    const filteredUsers: ISelect[] = (total_pragma_users || [])?.map(
      (user: { id: string; name: string; email: string }) => ({
        label: user?.name,
        value: user?.id,
        subtitle: user?.email,
      })
    );
    setPragmaticUsers([...filteredUsers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total_pragma_users]);

  useEffect(() => {
    const filterdRoles = (roles || [])
      .filter(
        (role: IRoles) => role?.name?.toLowerCase() === USER_ROLES?.VIEWER
      )
      ?.map((role: IRoles) => ({ label: role?.name, value: role?.id }));

    setFilteredRoles([...filterdRoles]);
  }, [roles]);

  const handleFormChange = () => {
    const hasErrors =
      !assignUsersForm.isFieldsTouched(["user"], true) ||
      assignUsersForm.getFieldsError().some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const assignUser = () => {
    dispatch(updateToggleLoader(true));
    const payload = {
      user: assignUsersForm.getFieldValue("user")[0],
      role: filteredRoles[0]?.value,
      customer: customerDetails.id,
    };
    dispatch(
      POST("assigned_users/assign_pragmatic_user", ApiUrl.assignPragmaticUser, {
        ...payload,
      })()
    ).then((res: any) => {
      dispatch(updateToggleLoader(false));
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(
          GET(
            "customerDetails/all_users",
            `${ApiUrl.customerList}${customerDetails?.id}/unassigned-users/`
          )()
        ); //Calling this API again to fetch updated users list inorder to fikter out custome rname
        const { name, type } = res?.payload?.data;
        triggerToastMessage(
          `${type ?? ""}${RESPONSE_MESSAGES.ASSIGN_USER.message}`,
          STATUS.SUCCESS,
          `${RESPONSE_MESSAGES.ASSIGN_USER.description}${type ?? ""} ${
            name ?? ""
          }`
        );
        reset();
        dispatch(reloadTable(true));
      }
    });
    dispatch(setAssignPragmaticUserModalOpenSt(false));
  };

  const reset = () => {
    dispatch(setAssignPragmaticUserModalOpenSt(false));
    assignUsersForm.resetFields();
    setBtnStatus(true);
  };

  return (
    <Wrapper>
      <CustomerDetailsAddModal
        width={632}
        destroyOnClose={true}
        className="modal-title"
        title="Assign Pragmatic User"
        open={openPragmaticUserModal}
        okText="Assign"
        okButtonProps={{ size: "large", disabled: btnStatus }}
        cancelButtonProps={{ size: "large", type: "text" }}
        onOk={assignUser}
        onCancel={reset}
        closable={false}
      >
        <span className="subtitle">
          Only one user can be selected and assigned at a time.
        </span>
        <div className="form-container">
          <Form
            className="user-assign-form"
            onFieldsChange={handleFormChange}
            autoComplete="off"
            size="large"
            form={assignUsersForm}
            layout="vertical"
            name="assignUsersForm"
            requiredMark={false}
          >
            <Form.Item
              className="form-item-user"
              name="user"
              label="Assign by entering the email or name"
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERRORS.selectUser,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <SelectOption
                mode="multiple"
                maxCount={1}
                style={{ width: "100%" }}
                placeholder="Select a User"
                options={pragmaticUsers}
              />
            </Form.Item>
            <Form.Item
              className="form-item-role"
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERRORS.selectUser,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <SelectOption
                disabled={true}
                maxCount={1}
                style={{ width: "100%" }}
                placeholder="Select a Role"
                defaultValue={filteredRoles[0]}
                options={filteredRoles}
              />
            </Form.Item>
          </Form>
        </div>
      </CustomerDetailsAddModal>
    </Wrapper>
  );
};

export default AssignUserModal;
