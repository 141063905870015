import styled from "styled-components";

const DynamicColorSelectWrapper = styled.div<{ type: string }>`
  .dynamic-color-select {
    .ant-select-selector {
      border: none !important;
      background-color: ${(props) =>
        `var(--${props.type?.toUpperCase()}) !important`};
    }
    .ant-select-selection-item {
      font-size: 1.8rem;
      font-weight: 500;
      color: ${(props) =>
        `var(--TEXT-${props?.type?.toUpperCase()}) !important`};
    }
  }
`;

export default DynamicColorSelectWrapper;
