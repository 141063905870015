import styled from "styled-components";

export const Wrapper = styled.section`
  background: var(--PAGE-NOT-FOUND-BG);
  padding: 2rem;
  height: 100%;
  .not-found-container {
    background: var(--WHITE);
    flex-direction: column;
    text-align: center;
    gap: 3.2rem;
    height: 100%;
    border-radius: 8px;
    .header {
      font-size: 2.4rem;
      color: var(--PRIMARY);
      font-weight: 500;
      margin: 0px;
    }
    .info {
      font-size: 1.8rem;
      font-weight: 400;
      color: var(--SECONDARY);
      .link {
        font-weight: 500;
        color: var(--TEXT-HIGHLIGHT);
        text-decoration: underline;
      }
    }
  }
  .sub-div {
    gap: 2.4rem;
    flex-direction: column;
  }
  .text-div {
    gap: 0.6rem;
    flex-direction: column;
  }
  .home-btn {
    color: var(--SECONDARY);
    font-weight: 600;
    border: 2px solid var(--SECONDARY);
  }
`;
