import { Row } from "antd";
import styled from "styled-components";

export const SearchInp = styled.div`
  .ant-input-affix-wrapper {
    height: 4.8rem !important;
    align-items: center;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
  .add-btn {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--WHITE);
    height: 4.6rem;
  }
`;

export const Wrapper = styled(Row)`
  .ant-table-tbody > tr > td .name {
    font-size: 1.6rem !important;
  }
  .nil {
    text-align: center;
  }
  .pagination {
    padding: 1.6rem 1rem 1.6rem 0rem;
    width: 100%;
    justify-content: space-between;
    .item-count {
      color: var(--TABLE-HEADER);
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
  .status .ant-table-column-title {
    margin-right: 1rem;
    flex: 0;
  }
  .status .ant-table-column-sorters {
    justify-content: flex-start;
  }
  .rotate-arrow {
    svg {
      transform: rotate(180deg);
    }
  }
  .action-btn {
    visibility: hidden;
  }
  .ant-table-row:hover .action-btn {
    display: inline-flex;
    visibility: visible;
  }
  .ant-table-row:hover .actions {
    display: inline-flex;
    visibility: visible;
  }
`;

export const StatusColumn = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4rem;
  .actions{
    visibility: hidden;
    }
  }
`;
