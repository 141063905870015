import { Button, Form, Input } from "antd";
import React, { ChangeEvent, useState } from "react";
import { STATUS, VALIDATION_ERRORS } from "../../../utils/constants/common";
import { recoveryCodeValidator, spaceRegex } from "../../../utils/validators";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { POST } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { updateToggleLoader } from "../../../store/features/Common.slice";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { BackArrow } from "../../../components/Icons/Icons";

const RecoveryCode = ({ backToLogin }: { backToLogin: Function }) => {
  const [recoveryCodeForm] = Form.useForm();
  const [btnStatus, setBtnStatus] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setItem } = useLocalStorage();

  const triggerValidationOnBlur = (field: string) => {
    recoveryCodeForm.validateFields([field]);
  };

  const handleFormChange = () => {
    const hasErrors =
      !recoveryCodeForm.isFieldsTouched(["code"], true) ||
      recoveryCodeForm.getFieldsError().some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const verifyRecoveryCode = () => {
    dispatch(updateToggleLoader(true));
    dispatch(
      POST("auth/recoveryCodeLogin", ApiUrl.recoveryCodeLogin, {
        ...recoveryCodeForm.getFieldsValue(),
      })()
    ).then((data: any) => {
      dispatch(updateToggleLoader(false));
      if (data?.payload?.status === STATUS.SUCCESS) {
        setItem("isAuthenticated", "true");
        navigate("/home");
      }
    });
  };

  const inputCode = (e: ChangeEvent<HTMLInputElement>) => {
    if (spaceRegex.test(e.target.value)) {
      e.target.value = "";
    }
  };

  const back = () => {
    backToLogin();
  };

  return (
    <>
      <Form
        onFieldsChange={handleFormChange}
        autoComplete="off"
        size="large"
        form={recoveryCodeForm}
        layout="vertical"
        onFinish={verifyRecoveryCode}
        name="recoveryCodeForm"
        requiredMark={false}
      >
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: VALIDATION_ERRORS.recoveryCode,
              validateTrigger: "onBlur",
            },
            {
              pattern: recoveryCodeValidator,
              message: VALIDATION_ERRORS.validCode,
              validateTrigger: "onBlur",
            },
          ]}
        >
          <Input
            onInput={inputCode}
            maxLength={8}
            autoFocus
            placeholder="xxxx-xxxx"
            size="large"
            onBlur={() => triggerValidationOnBlur("code")}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="large-btn"
            htmlType="submit"
            block
            type="primary"
            disabled={btnStatus}
          >
            Verify
          </Button>
        </Form.Item>
        <div className="d-flex place-center">
          <span className="cursor-pointer code-text link-back" onClick={back}>
            <BackArrow /> Go back to authentication
          </span>
        </div>
      </Form>
    </>
  );
};

export default RecoveryCode;
