import { Table, TableProps } from "antd";
import React from "react";
import {
  USER_LIST_TYPE,
  pragmaUserColumns,
  customerUserColumns,
} from "../../CustomerDetailsData";
import { LoaderLineComponent } from "../../../../components/Loader/Loader";
import { useAppSelector } from "../../../../hooks/reduxHooks";

const UserTable = ({
  item,
  changeTableProps,
}: {
  item: any;
  changeTableProps: TableProps<any>["onChange"];
}) => {
  const { user_table_loader, pragmaticUsers, customers } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const data =
    item?.type === USER_LIST_TYPE.PRAGMATIC_USERS
      ? pragmaticUsers?.data
      : customers?.data;
  return (
    <Table
      size="middle"
      scroll={{ x: true }}
      className="custom-table"
      onChange={
        item?.type === USER_LIST_TYPE.PRAGMATIC_USERS
          ? changeTableProps
          : () => {}
      }
      columns={
        item?.type === USER_LIST_TYPE.PRAGMATIC_USERS
          ? pragmaUserColumns
          : customerUserColumns
      }
      dataSource={data}
      pagination={false}
      rowKey="id"
      loading={
        item?.type === USER_LIST_TYPE.PRAGMATIC_USERS && {
          indicator: <LoaderLineComponent />,
          spinning: user_table_loader,
        }
      }
    />
  );
};

export default UserTable;
